/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
  /* font-family: 'Roboto Serif', sans-serif; */
}


@media screen and (max-width: 400px) {
  #founder {
    padding: 20px;
    width: 100%;
  }

  #courses,
  #testimonials,
  #videos,
  #contact,
  #footer {
    width: 100%;
  }

  #vissionmission {
    width: 100%;
    padding: 20px !important;
  }

  #vissionmission-paragraph {
    text-align: justify !important;
  }

  #menu {
    padding: 0;
  }

  #director {
    padding: 20px;
  }

  #freekipathshala {
    width: 100%;
    height: 30%;
  }

  .call-now-btn {
    text-align: center !important;
    margin-right: 0 !important;
  }

  .logo-image {
    width: 150px !important;
    /* padding-left: 25px; */
  }

  .page-scroll {
    /* float: left; */
    height: 50px;
    padding: 15px 15px;
    line-height: 20px;
    font-size: 18px;
  }

  #mobile-view {
    bottom: 15px !important;
    align-items: flex-end;
    left: 15px !important;
    /* right: 0; */
    display: block;
    cursor: pointer;
  }
}

.top-menubar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-prev,
.swiper-button-next {
  width: 30px !important;
  /* Smaller button */
  height: 30px !important;
  /* Smaller button */
  background-color: rgba(0, 0, 0, 0.5);
  /* Optional: Background color */
  border-radius: 50%;
  /* Circular buttons */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Reduce arrow icon size */
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 15px !important;
  /* Reduce arrow size */
  color: white;
  /* Ensure arrow is visible */
  font-weight: bold;
}

code {
  font-family: 'Inter', sans-serif;
}

body,
html {
  font-family: 'Inter', sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
}

h2,
h3,
h4,
h6 {
  font-family: 'Inter', sans-serif !important;
}

h2 {
  text-transform: uppercase;
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 36px;
  color: #333;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

h4 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}

h5 {
  font-family: 'Inter', sans-serif !important;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}

p {
  font-size: 15px;
  font-family: 'Inter', sans-serif !important;
}

p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}

a {
  color: #608dfd;
  font-weight: 400;
}

a:hover,
a:focus {
  text-decoration: none;
  color: #608dfd;
}

ul,
ol {
  list-style: none;
}

ul,
ol {
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}

/* hr {
  height: 2px;
  width: 70px;
  text-align: center;
  position: relative;
  background: #1e7a46;
  margin-bottom: 20px;
  border: 0;
} */

/* Navigation */

.navbar {
  margin-bottom: 0px !important;
}

#menu {
  padding: 5px;
  transition: all 0.8s;
}

#menu.navbar-default {
  background-color: #FFFFFF;
  border-color: rgba(231, 231, 231, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  /* border-bottom-left-radius: 65px;
  border-bottom-right-radius: 65px; */
}

#menu a.navbar-brand {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
}

#menu.navbar-default .navbar-nav>li>a {
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
  text-transform: initial;
}

#menu.navbar-default .navbar-nav>li>a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}

#whyfast.navbar-default .navbar-nav>li>a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}

#testseries.navbar-default .navbar-nav>li>a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}

#menu.navbar-default .navbar-nav>li>a:hover:after {
  width: 100%;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
  background-color: transparent;
}

.navbar-default .navbar-nav>.active>a:after,
.navbar-default .navbar-nav>.active>a:hover:after,
.navbar-default .navbar-nav>.active>a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}

.navbar-toggle {
  border-radius: 0;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}

.navbar-default .navbar-toggle:hover>.icon-bar {
  background-color: #608dfd;
}

.section-title {
  margin-bottom: 70px;
}

.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}

.section-title p {
  font-size: 18px;
}

.btn-custom {
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: #6372ff;
}

.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}

/* Header Section */
.intro {
  /* display: table; */
  width: 100%;
  padding: 0;
  /* background: url(../img/intro-bg.jpg) center center no-repeat; */
  /* background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover; */
}

.intro .overlay {
  /* background: rgba(0, 0, 0, 0.2); */
}

.intro h1 {
  font-family: 'Inter', sans-serif;
  color: #fff;
  font-size: 82px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}

.intro h1 span {
  font-weight: 800;
  color: #5ca9fb;
}

.intro p {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
}

header .intro-text {
  padding-top: 350px;
  padding-bottom: 200px;
  text-align: center;
}

/* Features Section */
#founder {
  padding: 0 0;
}

#founder h3 {
  font-size: 22px;
  margin: 0 0 20px;
}

#founder h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  text-transform: none;
}

#founder h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}

#founder .features-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
}

#founder .features-text li:before {
  content: "\f00c";
  font-family: 'Inter', sans-serif;
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}

#founder img {
  width: 520px;
  margin-top: 10px;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}

#founder p {
  line-height: 24px;
  margin: 30px 0;
}

#founder i.fa {
  font-size: 38px;
  margin-bottom: 20px;
  transition: all 0.5s;
  color: #fff;
  width: 100px;
  height: 100px;
  padding: 30px 0;
  border-radius: 50%;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}

/* director Section */
#director {
  padding: 100px 40px;
  background: #f6f6f6;
}

#director h3 {
  font-size: 22px;
  margin: 0 0 20px;
}

#director h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

#director h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}

#director .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
}

#director .about-text li:before {
  content: "\f00c";
  font-family: 'Inter', sans-serif;
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}

#director img {
  width: 520px;
  margin-top: 10px;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}

#director p {
  line-height: 24px;
  margin: 30px 0;
}

/* Services Section */
#courses {
  padding: 100px 0;
  background: #290404;
  color: #fff;
}

#courses .service-desc {
  margin: 10px 10px 20px;
}

#courses h2 {
  color: #fff;
}

#courses .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}

#courses i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}

#courses h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #fff;
}

#courses p {
  color: rgba(255, 255, 255, 0.75);
}

#courses .service-desc {
  margin-bottom: 40px;
}

/* Portfolio Section */
/* #vissionmission {
  padding: 100px 0;
} */
.portfolio-item {
  margin: 1px -15px 0 -14px;
  padding: 0;
}

.portfolio-item .hover-bg {
  overflow: hidden;
  position: relative;
  margin: 0;
}

.hover-bg .hover-text {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(to right,
      rgba(99, 114, 255, 0.8) 0%,
      rgba(92, 169, 251, 0.8) 100%);
  padding: 30% 0 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
}

.hover-bg .hover-text>h4 {
  opacity: 0;
  color: #fff;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 0.3s;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
}

.hover-bg:hover .hover-text>h4 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.hover-bg:hover .hover-text {
  opacity: 1;
}

/* Testimonials Section */
#testimonials {
  padding: 100px 0;
  background: #f6f6f6;
}

#testimonials i {
  color: #e6e6e6;
  font-size: 32px;
  margin-bottom: 20px;
}

.testimonial {
  position: relative;
  padding: 20px;
}

.testimonial-image {
  float: left;
  margin-right: 15px;
}

.testimonial-image,
.testimonial-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.testimonial-content {
  position: relative;
  overflow: hidden;
}

.testimonial-content p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
}

.testimonial-meta {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #666;
}

/* Team Section */
#videos {
  padding: 100px 0;
}

#videos h4 {
  margin: 5px 0;
}

#videos .team-img {
  width: 100%;
  height: 70%;
}

#videos .thumbnail {
  background: transparent;
  border: 0;
}

#videos .thumbnail .caption {
  padding: 10px 0 0;
  color: #888;
}

/* Contact Section */
#contact {
  /* padding: 100px 0 0; */
  background: #002460;
  color: #fff;
  /* border-top-left-radius: 65px;
  border-top-right-radius: 65px; */
}

#contact .section-title {
  margin-bottom: 40px;
}

#contact .section-title p {
  font-size: 16px;
}

#contact h2 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

#contact .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 30px;
}

/* #contact h3 {
  color: #fff;
  margin-top: 80px;
  margin-bottom: 25px;
  padding-bottom: 20px;
  font-weight: 400;
} */
#contact form {
  padding-top: 20px;
}

#contact .text-danger {
  color: #cc0033;
  text-align: left;
}

#contact .btn-custom {
  margin: 30px 0;
  background: transparent;
  border: 2px solid #fff;
}

#contact .btn-custom:hover {
  color: #1f386e;
  background: #fff;
}

label {
  font-size: 12px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  float: left;
}

#contact .form-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

#contact .form-control:focus {
  border-color: #999;
  outline: 0;
  -webkit-box-shadow: transparent;
  box-shadow: transparent;
}

.form-control::-webkit-input-placeholder {
  color: #777;
}

.form-control:-moz-placeholder {
  color: #777;
}

.form-control::-moz-placeholder {
  color: #777;
}

.form-control:-ms-input-placeholder {
  color: #777;
}

#contact .contact-item {
  margin: 20px 0;
}

#contact .contact-item span {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  display: block;
}

#contact .contact-item i.fa {
  margin-right: 10px;
}

#contact .social {
  /* border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 50px;
  margin-top: 50px; */
  /* text-align: center; */
  /* margin-bottom: 50px; */
}

#contact .social ul li {
  display: inline-block;
  /* margin: 0 10px; */
}

#contact .social i.fa {
  font-size: 22px;
  padding: 12px;
  /* width: 48px;
  height: 48px;
  border: 2px solid #fff; */
  color: #fff;
  /* border-radius: 50%; */
  transition: all 0.3s;
}

#contact .social i.fa:hover {
  color: #608dfd;
  background: #fff;
  padding: 12px;
  border-radius: 8px;
}

/* Footer Section*/
#footer {
  background: #f6f6f6;
  padding: 30px 0;
}

#footer p {
  color: #888;
  font-size: 14px;
}

#footer a {
  color: #608dfd;
}

#footer a:hover {
  border-bottom: 2px solid #608dfd;
}

.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 800px;
  /* change this to whatever you want */
  width: auto;
}

#Carousle {
  margin-top: 84px;
}

#vissionmission {
  overflow: hidden;
}

/* #whyfast {
  overflow: hidden;
} */

#vissionmission-paragraph {
  text-align: left;
}

.calling-floating-btn {
  background: white;
  font-size: 30px;
  padding: 13px 16px;
  border-radius: 50%;
  color: blue;
}

#mobile-view {
  bottom: 90px;
  align-items: flex-end;
  right: 15px;
  cursor: pointer;
}

.background-image-why {
  display: none;
}

.button-hover:hover {
  background-color: #001C4A !important;
  color: #fff !important;
}

.mobile-home-image {
  display: none;
}

.filter-btn {
  display: flex;
}

.mobile-plan-box {
  /* height: 312px !important; */
  /* overflow: scroll !important; */
  display: none;
}

.desktop-plan-box {
  display: block;
}

.react-multi-carousel-list {
  display: none !important;
}

.mobile-view-discrip {
  display: none !important;
}

.desktop-view-discrip {
  display: block !important;
}

.mobile-view-schedule {
  display: flex;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.desktop-view-checkout {
  position: fixed !important;
  left: 50% !important;
  right: 50% !important;
  bottom: 8px !important;
  z-index: 999 !important;
  margin: 0 5px !important;
  width: max-content !important;
}

.classcart-whatsapp-avatar {
  height: auto !important;
}

.hover-image {
  width: 100%;
  border-radius: 10px;
  padding: 35px;
  min-height: 150px;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.hover-image:hover {
  /* background: #002460; */
  box-shadow: rgba(0, 0, 0, 0.15) 6.95px 5.95px 4.6px;
  transform: scale(1.05);
  /* Optional */
}

.employee-slider {
  overflow-x: clip;
}

@media (max-width: 425px) {
  .react-multi-carousel-list {
    display: flex !important;
  }

  .desktop-plan-box {
    display: none !important;
  }

  .ratting-box {
    margin-top: 30px;
  }

  .mobile-logo-img {
    width: 100px !important;
  }
}


@media (max-width: 768px) {
  .top-menubar {
    display: block;
    align-items: center;
    justify-content: center;
  }

  .mobile-nav {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    /* padding-bottom: 12px; */
  }

  .mobile-view-schedule {
    display: flex;
    align-items: center;
    justify-content: end !important;
    margin-bottom: 20px;
  }

  .mobile-view-checkout {
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 8px !important;
    z-index: 999 !important;
    margin: 0 5px !important;
  }

  .mobile-view-discrip {
    display: block !important;
  }

  .desktop-view-discrip {
    /* display: none !important; */
  }

  .stack-mobile {
    display: block !important;
  }

  .mobile-view-image {
    width: 90% !important;
  }

  .react-multi-carousel-list {
    display: flex !important;
  }

  .desktop-plan-box {
    display: none !important;
  }

  .mobile-headint-margin {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mobile-accordian {
    position: unset !important;
    padding: 0px 20px 0px 20px;
  }

  .filter-btn {
    display: block !important;
  }

  .mobile-filter-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .home-image {
    display: none !important;
  }

  .mobile-home-image {
    display: block !important;
  }

  #director img {
    margin: 50px 0;
  }

  #whyfast {
    margin-bottom: 0 !important;
  }

  #mobile-view {
    bottom: 15px !important;
    align-items: flex-end;
    left: 15px !important;
    /* right: 0; */
    display: block;
    cursor: pointer;
  }

  #vissionmission-paragraph {
    margin-left: 20px;
  }

  .select-option {
    max-width: 100% !important;
    width: 100% !important;
  }

  .mobile-select-button {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .first-section-img {
    padding: 0 !important;
  }

  .whyfast-text {
    font-size: 32px !important;
    margin-bottom: 0 !important;
    display: block !important;
  }

  .background-image-why {
    background-image: none !important;
  }

  .background-image-why {
    display: block;
  }

  .mobile-fts-heading {
    text-align: center !important;
  }

  .mobile-high-rated {
    display: none !important;
  }

  #testseries {
    margin: 0 !important;
  }

  .mobile-text-high {
    font-size: 32px !important;
    text-align: left !important;
    display: block !important;
  }

  .fts-mobile-col {
    margin: 0 !important;
    padding: 0 !important;
  }

  .mobile-plan-des {
    display: block !important;
  }

  .mobile-fill-textfield {
    padding: 10px !important;
    margin: 0 !important;
  }

  .mobile-coupon-field {
    /* padding: 10px !important; */
    margin: 0 !important;
  }

  .mobile-any-heading {
    font-size: 26px !important;
  }

  .section-title p {
    font-size: 14px !important;
    margin-bottom: 30px !important;
  }

  .mobile-search {
    width: 100% !important;
    margin: 0 !important;
  }

  #videos {
    padding: 0 !important;
  }

  .footer-second-img {
    width: 300px !important;
  }

  .footer-first-section {
    text-align: left !important;
  }

  .contact-item {
    text-align: left !important;
    padding-left: 20px !important;
  }

  .footer-img-right {
    /* width: -webkit-fill-available !important; */
    margin-top: 0 !important;
    /* margin-bottom: -49% !important; */
    height: 100% !important;
    padding: 90px 0px 0px 0px !important;
  }

  .footer-right-image {
    position: absolute;
    top: 0 !important;
    bottom: 0 !important;
    /* left: 0; */
    right: 0 !important;
  }

  .mobile-plan-box {
    /* height: 312px !important; */
    /* overflow: scroll !important; */
    display: block !important;
  }

  ::-webkit-scrollbar {
    width: 7px;
    /* display: none; */
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .mobile-suggested {
    margin-left: 0 !important;
  }

  #menu.navbar-default {
    background-color: #FFFFFF;
    /* border-color: #002460; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    /* border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px; */
  }

  #contact {
    /* padding: 100px 0 0; */
    background: #002460;
    color: #fff;
    /* border-top-left-radius: 25px;
    border-top-right-radius: 25px; */
  }

  .navbar-toggle {
    margin-top: 20px !important;
  }

  .footer-first-logo {
    height: 45px !important;
    margin-top: 17px !important;
  }

  .section-title {
    margin: 20px !important;
  }

  .mobile-text-div {
    padding: 0 !important;
    margin-left: 25px !important;
    margin-top: 30px !important;
  }

  .fts-full-image {
    position: unset !important;
  }

  .mobile-group-btn {
    margin-right: 0 !important;
  }

  .mobile-group-btn:hover {
    background-color: red !important;
    color: #fff !important;
  }

  #menu.navbar-default .navbar-nav>li>a {
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    padding: 8px 2px;
    border-radius: 0;
    margin: 0px 20px 0;
    text-transform: initial;
  }

  .mobile-buy-now {
    width: 100% !important;
  }
}

@media (max-width: 1024px) {
  .background-image-why {
    background-position: initial !important;
  }

  .img-responsive {
    width: 130px !important;
  }

  #menu.navbar-default .navbar-nav>li>a {
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    padding: 8px 2px;
    border-radius: 0;
    margin: 0px 20px 0;
    text-transform: initial;
  }
}

@media (max-width: 1440px) {
  .img-responsive {
    width: 130px !important;
  }
}